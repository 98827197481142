import cx from "classnames";
import CarrierServiceArea from "components/CarrierDetails/CarrierServiceArea";
import styles from "./CarrierFilterPopup.module.scss";
import { getModesArray } from "shared/utils/modesHelper";

const AdditionalLocationsFilterSection = ({
  optionsData,
  filterPopup,
  handleUpdatFilters,
}) => {
  return (
    <div
      className={cx(
        styles.sectionContainer,
        styles.additionalLocationsSections
      )}
    >
      <div>
        <label className={styles.formItemLabel}>Pickup Service Area:</label>
        <CarrierServiceArea
          selectedItems={optionsData.serviceAreas?.filter((area) =>
            getModesArray(filterPopup.pickupServiceAreas || '')?.includes(area?.serviceArea)
          ) || []}
          options={
            optionsData.serviceAreas?.filter((x) =>
              getModesArray(filterPopup.pickupServiceAreas || '')?.every(
                (y) => y !== x?.serviceArea
              )
            ) || []
          }
          addArea={(item) =>
            handleUpdatFilters('pickupServiceAreas', [...getModesArray(filterPopup.pickupServiceAreas || ''), item.serviceArea].join(','))
          }
          removeArea={(item) =>
            handleUpdatFilters('pickupServiceAreas', getModesArray(filterPopup.pickupServiceAreas || '').filter(area => area !== item.serviceArea).join(','))
          }
          showConfirm={false}
        />
      </div>
      <div>
        <label className={styles.formItemLabel}>Delivery Service Area:</label>
        <CarrierServiceArea
          selectedItems={optionsData.serviceAreas?.filter((area) =>
            getModesArray(filterPopup.deliveryServiceAreas || '')?.includes(area?.serviceArea)
          ) || []}
          options={
            optionsData.serviceAreas?.filter((x) =>
              getModesArray(filterPopup.deliveryServiceAreas || '')?.every(
                (y) => y !== x?.serviceArea
              )
            ) || []
          }
          addArea={(item) =>
            handleUpdatFilters('deliveryServiceAreas', [...getModesArray(filterPopup.deliveryServiceAreas || ''), item.serviceArea].join(','))
          }
          removeArea={(item) =>
            handleUpdatFilters('deliveryServiceAreas', getModesArray(filterPopup.deliveryServiceAreas || '').filter(area => area !== item.serviceArea).join(','))
          }
          showConfirm={false}
        />
      </div>
    </div>
  );
};

export default AdditionalLocationsFilterSection;
