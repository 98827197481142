import { useMemo, useState } from "react";
import { debounce } from "lodash";

import { Option } from "models/Option";
import { getLocationsList } from "pages/CoveragePlans/CoveragePlanForm/api/coveragePlanFormApiCalls";
import {
  getUniqueOptions,
  mapLocationsToOptions,
} from "pages/CoveragePlans/CoveragePlanForm/utils/coveragePlanApiToOptionMappers";
import { inputDebounceDelay } from "./constants";

export const useGetLocationOptions = (initialLocations: string[]) => {
  const [locationOptions, setLocationOptions] = useState<Option<string>[]>(() =>
    getUniqueOptions(
      initialLocations.reduce<Option<string>[]>((acc, location) => {
        if (location) {
          acc.push({ key: location, value: location, text: location });
        }
        return acc;
      }, [])
    )
  );
  const [locationOptionsLoading, setLocationOptionsLoading] = useState(false);

  const debouncedLocationSearch = useMemo(
    () =>
      debounce(async (search: string) => {
        setLocationOptionsLoading(true);
        const locationsList = await getLocationsList(search);
        setLocationOptions((currentLocationOptions) =>
          getUniqueOptions([
            ...currentLocationOptions,
            ...mapLocationsToOptions(locationsList),
          ])
        );
        setLocationOptionsLoading(false);
      }, inputDebounceDelay),
    []
  );

  return { locationOptions, locationOptionsLoading, debouncedLocationSearch };
};
