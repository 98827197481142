import React, { useCallback, useMemo } from "react";
import momentTz from "moment-timezone";
import cx from "classnames";
import { Icon, Label } from "semantic-ui-react";

import CarrierTriangleBadge from "../CarrierTriangleBadge";
import CarrierStar from "components/CarrierStar";
import TableProfileImage from "components/TableProfileImage";

import { formatPhoneNumberWithExtentionAndCountryCode } from "shared/utils/utility";
import * as activityConstants from "shared/constants/activityConstants";

import { Activity } from "../../../models/Activity";
import { CarrierEntity } from "../models";

import styles from "./CarrierDividedList.module.scss";

type MinifiedListRowProps = {
  item: CarrierEntity;
  onRowClick: (item: CarrierEntity) => void;
  onEditCarrier: (id: number) => void;
  active: boolean;
};

const createContentSummary = (act: Activity) => {
  const summaryEntityType =
    activityConstants.entityTypes.find((type) => type.key === act.entityType)
      ?.text ?? "";

  const summaryAction =
    activityConstants.activityTypes.find((type) => type.key === act.actionType)
      ?.text ?? "";

  const user = act.userName ? `${act.userName}: ` : "";

  return { user, summaryAction, summaryEntityType };
};

const MinifiedListRow = (props: MinifiedListRowProps) => {
  const phoneValue = useMemo(() => {
    if (!props.item.phone) return "Phone n/a";

    const phone = {
      phoneNumber: props.item.phone?.phoneNumber ?? "",
      countryCode: props.item.phone?.countryCode ?? "",
      extension: props.item.phone?.extension ?? "",
    };

    return formatPhoneNumberWithExtentionAndCountryCode(phone);
  }, [props.item.phone]);

  const lastActivityTime = props.item?.lastActivity?.createdAt
    ? momentTz
      .utc(props.item.lastActivity.createdAt)
      .tz("America/New_York")
      .format("MM/DD/YY h:mm A")
    : "never";

  const lastActivityVal = props.item.lastActivity
    ? createContentSummary(props.item.lastActivity)
    : null;

  const handleCarrierEdit = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      props.onEditCarrier(props.item.id);
    },
    [props]
  );

  return (
    <button
      type="button"
      className={cx(styles.carrierRow, props.active && styles.activeRow)}
      title={props.active ? "active" : "inactive"}
      id={`minified_list_row_${props.item.id}`}
      aria-pressed={props.active}
      onClick={() => props.onRowClick(props.item)}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          props.onRowClick(props.item);
        }
      }}
    >
      <div className={styles.cell}>
        <CarrierTriangleBadge
          key={`${props.item.id}_ctb`}
          turvoId={props.item.turvoId}
          statusId={props.item.statusCodeId}
        />
        <TableProfileImage item={props.item} />
        <CarrierStar
          key={`cstar_${props.item.id}`}
          starredByCurrentUser={props.item.isStarredByCurrentUser}
          starredByOthers={props.item.starredByUsers}
        />
        <Icon
          className={styles.editIconContainer}
          name="edit"
          title="edit-icon"
          link
          onClick={handleCarrierEdit}
        />
      </div>
      <div className={cx(styles.cell, styles.twentyFive)}>
        <p title={props.item.name}>{props.item.name}</p>
        <p className={styles.subtext}>
          Owner: {props.item.owner ? props.item.owner.name : "n/a"}
        </p>
        <p className={styles.subtext}>MC#: {props.item.mcNumber}</p>
        <p className={styles.subtext}>DOT#: {props.item.dotNumber}</p>
      </div>
      <div className={cx(styles.cell, styles.thirty)}>
        {phoneValue && (
          <p>
            <Icon name="phone" color="grey" size="small" />
            {phoneValue}
          </p>
        )}
        {props.item.email && (
          <p title={props.item.email} className={styles.subtext}>
            <Icon name="mail" color="grey" size="small" />
            {props.item.email}
          </p>
        )}
        {props.item.address && (
          <p>
            <Icon name="globe" color="grey" size="small" />
            {props.item.address}
          </p>
        )}
      </div>
      <div className={cx(styles.cell, styles.twentyFive)}>
        <p title={lastActivityTime}>{lastActivityTime}</p>
        {props.item?.lastActivity?.notes && (
          <p title={props.item?.lastActivity?.notes} className={styles.subtext}>
            {props.item?.lastActivity?.notes}
          </p>
        )}
        {!lastActivityVal && <p className={styles.subtext}>none</p>}
        {lastActivityVal?.user && (
          <p className={styles.subtext} title={lastActivityVal?.user}>
            {lastActivityVal.user}
          </p>
        )}
        {lastActivityVal?.summaryAction && (
          <p className={styles.subtext} title={lastActivityVal?.summaryAction}>
            {lastActivityVal.summaryAction}
          </p>
        )}
        {lastActivityVal?.summaryEntityType && (
          <p
            className={styles.subtext}
            title={lastActivityVal?.summaryEntityType}
          >
            {lastActivityVal.summaryEntityType}
          </p>
        )}
      </div>
      <div className={cx(styles.cell, styles.nine)}>
        <p>
          {props.item.totalActiveMatches ?? 0}/
          {props.item.totalActiveCapacities ?? 0}
        </p>
      </div>

      {props.item.unseenNotificationsCount > 0 && (
        <Label className={styles.unseenLabel} circular color="blue" size="tiny">
          {props.item.unseenNotificationsCount > 99
            ? "99+"
            : props.item.unseenNotificationsCount}
        </Label>
      )}
    </button>
  );
};

export default MinifiedListRow;
