import { Segment, Header, Image } from "semantic-ui-react";

import { CarrierEntity } from "components/CarrierList/models";
import { generateIcon } from "shared/utils/utility";

import styles from "./TableProfileImage.module.scss";

type TableProfileImageProps = {
  item: CarrierEntity;
  customContent?: React.ReactNode;
}

const TableProfileImage = (props: TableProfileImageProps) => {
  return (
    <div className={styles.iconContainer}>
      {props.item.iconUrl ? (
        <div className={styles.imageBar}>
          <Image
            title="profileImage"
            circular
            src={props.item.iconUrl}
            className={styles.image}
          />
        </div>
      ) : (
        <Segment circular className={styles.profileIcon}>
          <Header as="h1" className={styles.profileTitle}>
            {props.customContent ?? generateIcon(props.item)}
          </Header>
        </Segment>
      )}
    </div>
  );
};

export default TableProfileImage;
