import axios from "axios";

import { QueryGridResponse } from "../../../../models/QueryGridResponse";
import { CustomersListResponse } from "../../../../models/CustomersListResponse";
import { EquipmentModeCategoryDto } from "../../../../models/dto/EquipmentModeCategoryDto";
import { TurvoUserDto } from "../../../../models/dto/TurvoUserDto";
import { CoveragePlanDto } from "../../../../models/dto/CoveragePlanDto";

export const getNameExists = (name: string): Promise<boolean> =>
  axios
    .get<boolean>("/api/coverageplan/nameexists", { params: { name } })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return false;
    });

export const getCustomersList = (
  name: string
): Promise<QueryGridResponse<CustomersListResponse>> =>
  axios
    .get<QueryGridResponse<CustomersListResponse>>("/api/customers/list", {
      params: { name },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return { filteredCount: 0, entities: [] };
    });

export const getUsersList = (
  name: string
): Promise<QueryGridResponse<TurvoUserDto>> =>
  axios
    .get<QueryGridResponse<TurvoUserDto>>("/api/users/turvo/list", {
      params: {
        name,
        page: 1,
        pageSize: 10,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return { filteredCount: 0, entities: [] };
    });

export const getEquipmentList = (): Promise<EquipmentModeCategoryDto[]> =>
  axios
    .get<EquipmentModeCategoryDto[]>("/api/options/equipment")
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return [];
    });

export const getLocationsList = (searchPhrase: string): Promise<string[]> =>
  axios
    .get<string | string[]>("/api/shipments/searchLocation", {
      params: { searchPhrase },
    })
    .then((response) => {
      // This endpoint sends an empty string in some scenarios
      if (typeof response.data === "string") {
        return [];
      }

      return response.data;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });

export const postCoveragePlan = (
  data: CoveragePlanDto
): Promise<CoveragePlanDto> =>
  axios
    .post<CoveragePlanDto>("/api/coverageplan/create", data)
    .then((response) => response.data);

export const putCoveragePlan = (
  data: CoveragePlanDto
): Promise<CoveragePlanDto> =>
  axios
    .put<CoveragePlanDto>("/api/coverageplan/update", data)
    .then((response) => response.data);
