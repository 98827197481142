import axios from "axios";
import { toast } from "react-toastify";

interface UpdateCarrierProps {
  profilesCarrierId: number;
  marketplaceId: string;
  organizationName: string;
  reason: string;
  handleCloseModalAfterUpdate: () => void;
}

export const fetchCarrierList = (search: { search: string }) => {
  return axios.get(`/api/marketplace/carriers-list?search=${search.search}`);
};

export const updateCarrierActiveStatus = async ({
  profilesCarrierId,
  marketplaceId,
  organizationName,
  reason,
  handleCloseModalAfterUpdate,
}: UpdateCarrierProps) => {
  try {
    const res = await axios.post("/api/marketplace/updateCarrierActiveStatus", {
      profilesCarrierId,
      marketplaceId,
      organizationName,
      reason,
    });
    handleCloseModalAfterUpdate();
    res.data.success
      ? toast.success("Carrier Marketplace Organization updated successfully!")
      : toast.error(res.data.error);
  } catch {
    handleCloseModalAfterUpdate();
    return toast.error("Error occured while updating carrier details!");
  }
};
