import {
  CarrierCapacityInfoDto,
  CarrierCapacityTypeInfoDto,
} from "models/dto/CarrierCapacityInfoDto";
import { CarrierCapacityFormObject } from "../CarrierCapacityForm";

export const convertToFormDateString = (dateString: string): string => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "";
  }

  return new Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
};

export const mapApiToFormObject = (
  carrierCapacityInfo: CarrierCapacityInfoDto<CarrierCapacityTypeInfoDto>
): CarrierCapacityFormObject => {
  return {
    id: carrierCapacityInfo.id,
    type: carrierCapacityInfo.type,
    date: carrierCapacityInfo.expirationDate
      ? convertToFormDateString(carrierCapacityInfo.expirationDate)
      : "",
    origin: carrierCapacityInfo.origin ?? "",
    destination: carrierCapacityInfo.destination ?? "",
    rate: carrierCapacityInfo.rate?.toString() ?? "",
    equipmentModeCategoryId:
      carrierCapacityInfo.equipmentModeCategoryId ?? null,
    note: carrierCapacityInfo.notes ?? "",
  };
};
