import Loader from "components/Loader";
import MinifiedListRow from "./MinifiedListRow";
import { ListBodyProps } from "components/CarrierList/models";

import styles from "../CarrierList.module.scss";

const MinifiedListBody = (props: ListBodyProps) => {
  if (props.carriersLoading) {
    return <Loader />;
  }

  if (!props.carrierData || props.carrierData.length === 0) {
    return <p className={styles.noData}>No data to display</p>;
  }

  return (
    <>
      {
        props.carrierData.map((item) => (
          <MinifiedListRow
            key={`mlr_${item.id}`}
            onRowClick={props.onRowClick}
            item={item}
            onEditCarrier={props.onEditCarrier}
            active={props.selectedCarrier?.id === item.id}
          />
        ))
      }
    </>
  );
};

export default MinifiedListBody;
