import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Dimmer, Loader, Modal } from "semantic-ui-react";

import CarrierPlanCapacityForm, {
  CarrierPlanCapacityFormObject,
} from "./CarrierPlanCapacityForm";
import { useGetCoveragePlans } from "pages/CoveragePlans/CoveragePlanForm/api/hooks/useGetCoveragePlans";
import { mapFormToApiObject } from "./utils/carrierPlanCapacityFormToApiMappers";
import {
  postCarrierPlanCapacity,
  putCarrierPlanCapacity,
} from "../../api/carrierCapacityApi";
import { CarrierPlanCapacityTypeInfoDto } from "models/dto/CarrierCoveragePlanCapacityDto";

type CarrierPlanCapacityFormModalProps = {
  onClose: () => void;
  carrierId: number;
  carrierName: string;
  defaultValues: CarrierPlanCapacityFormObject;
  onSubmitCallback: () => void;
};

const capacityTypeToTextMap: Record<CarrierPlanCapacityTypeInfoDto, string> = {
  CoveragePlan: "Coverage Plan Capacity",
  NoPlanCapacity: "No Plan Capacity",
};

const CarrierPlanCapacityFormModal = (
  props: CarrierPlanCapacityFormModalProps
) => {
  const { data, isLoading } = useGetCoveragePlans({
    statuses: ["Active"],
    page: 1,
    pageSize: 100,
    sortField: "name",
    dir: "asc",
  });

  const {
    control,
    errors,
    watch,
    setValue,
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<CarrierPlanCapacityFormObject>({
    defaultValues: props.defaultValues,
  });

  register({ name: "id" });
  register({ name: "type" });

  const onSubmit = async (formObject: CarrierPlanCapacityFormObject) => {
    const apiObject = mapFormToApiObject(formObject);

    if (!apiObject) {
      toast.error(
        "There was an error in form validation.  Please check your entries and try again."
      );
    } else {
      try {
        apiObject.id
          ? await putCarrierPlanCapacity({
              carrierId: props.carrierId,
              data: apiObject,
            })
          : await postCarrierPlanCapacity({
              carrierId: props.carrierId,
              data: apiObject,
            });

        toast.success("Plan capacity saved successfully");

        props.onSubmitCallback();
        props.onClose();
      } catch (error) {
        console.error(error);
        toast.error("There was an error saving the plan capacity");
      }
    }
  };

  return (
    <Modal size="large" open onClose={props.onClose}>
      <Modal.Header>
        {props.defaultValues.id ? "Edit" : "Add"}{" "}
        {capacityTypeToTextMap[props.defaultValues.type]} - {props.carrierName}
      </Modal.Header>

      <Modal.Content>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <CarrierPlanCapacityForm
          control={control}
          errors={errors}
          setValue={setValue}
          planId={watch("planId")}
          laneId={watch("laneId")}
          closeForm={props.onClose}
          defaultValues={props.defaultValues}
          coveragePlans={data?.entities ?? []}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button type="button" negative onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CarrierPlanCapacityFormModal;
