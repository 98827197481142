import axios, {AxiosRequestConfig} from "axios";
import {  toast } from 'react-toastify';
import {AttributeWithValue, Carrier} from "../models/Carrier";

export const fetchCarrierById = (carrierId: string) => {
  return axios.get(`/api/carriers/${carrierId}`); 
};

export const starCarrier = (carrierId: string) => {
  return axios.post(`/api/carriers/${carrierId}/star`, { carrierId });
};

export const fetchCarrierSummary = (carrierId: string) => {
  return axios.get(`/api/carriers/${carrierId}/summary`, { carrierId } as AxiosRequestConfig);
};

export const fetchCarrierOwners = (searchQuery: string) => {
  return axios.get(`/api/users/turvo/list?name=${searchQuery}&page=1&pageSize=100`);
};

export const fetchCarrierOptions = () => {
  return axios.get("/api/carriers/options/edit");
};

export const saveCarrier = async (carrier: Carrier) => {
  try {
    await axios.put("/api/carriers", carrier);
    toast.success("Carrier details have been saved!");
  } catch {
    return toast.error("Error occured while saving carrier details!");
  }
};

export const fetchCarrierAttributes = () => {
  return axios.get(`/api/carriers/attributes`);
};

export const saveCarrierAttributes = async ({ carrierId, attributeList }: {carrierId: string, attributeList: AttributeWithValue}) => {
  try {
    await axios.put(`/api/carriers/${carrierId}/attributes`, {
      attributes: attributeList,
    });
    toast.success("Carrier attributes have been saved!");
  } catch (err) {
    return toast.error("Error occured while saving carrier attributes!");
  }
};

export const fetchCarrierHistory = ({ carrierId, gridState }: {carrierId: string, gridState: {
    dir: string;
    name: string;
    page: number;
    pageSize: number;
    sortField: string;
    total: number;
  }}) => {
  return axios.get(
    `/api/carrierHistory/list?carrierId=${carrierId}&pageSize=${gridState.pageSize}&pageNumber=${gridState.page}`
  );
};

export const fetchCarrierDocuments = (carrierId: string, gridState: {
  dir: string;
  name: string;
  page: number;
  pageSize: number;
  sortField: string;
  total: number;
}) => {
  return axios.get(`/api/carriers/${carrierId}/document/list?carrierId=${carrierId}&pageSize=${gridState.pageSize}&pageNumber=${gridState.page}`);
};

export const filterCarrierDocuments = (carrierId: string, gridState: {
  dir: string;
  name: string;
  page: number;
  pageSize: number;
  sortField: string;
  total: number;
}) => {
  return axios.get(`/api/carriers/${carrierId}/document/search?carrierId=${carrierId}`,{ params: gridState });
};

export const saveDocumentNotes = async (carrierId: string, notes: {
  id: number;
  notes: string;
}) => {
  try {
    await axios.put(
      `/api/carriers/${carrierId}/document/notes`,
      notes,
    );
    toast.success("Document notes have been saved!");
  } catch (err) {
    return toast.error("Error occured while saving document notes!");
  }
};

export const updateCarrierDocument = (carrierId: string, formData: unknown) => {
  return axios.put(`/api/carriers/${carrierId}/document`, formData);
};

export const addCarrierDocument = (carrierId: string, formData: unknown) => {
  return axios.post(`/api/carriers/${carrierId}/document`, formData);
};

export const deleteCarrierDocument = async (carrierId: string, documentId: number) => {
  try {
    await axios.delete(
      `/api/carriers/${carrierId}/document/${documentId}`,
    );
    toast.success("Document have been deleted!");
  } catch (err) {
    return toast.error("Error occured while deleting document!");
  }
};
