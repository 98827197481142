import { isEqual, uniqWith } from "lodash";

import { EquipmentModeCategoryDto } from "../../../../models/dto/EquipmentModeCategoryDto";
import { CustomersListResponse } from "../../../../models/CustomersListResponse";
import { QueryGridResponse } from "../../../../models/QueryGridResponse";
import { TurvoUserDto } from "../../../../models/dto/TurvoUserDto";
import { Option } from "models/Option";

export const mapUsersToOptions = (
  data: QueryGridResponse<TurvoUserDto>
): Option<string>[] =>
  data.entities.reduce<Option<string>[]>((acc, user) => {
    if (user.id && user.name) {
      acc.push({
        key: user.id,
        value: user.id,
        text: user.name,
      });
    }
    return acc;
  }, []);

export const mapCustomersToOptions = (
  data: QueryGridResponse<CustomersListResponse>
): Option<number>[] =>
  data.entities.map((customer) => ({
    key: customer.id,
    value: customer.id,
    text: customer.name,
  }));

export const mapEquipmentToOptions = (
  data: EquipmentModeCategoryDto[]
): Option<number>[] =>
  data.map((equipment) => ({
    key: equipment.id,
    value: equipment.id,
    text: equipment.text,
  }));

export const mapLocationsToOptions = (data: string[]): Option<string>[] =>
  data.map((location) => ({
    key: location,
    value: location,
    text: location,
  }));

export const getUniqueOptions = <T>(options: Option<T>[]) =>
  uniqWith(options, isEqual).sort((a, b) => a.text.localeCompare(b.text));
