import useSWR from "swr";
import axios, { AxiosError } from "axios";

import { EquipmentModeCategoryDto } from "models/dto/EquipmentModeCategoryDto";
import { mapEquipmentToOptions } from "pages/CoveragePlans/CoveragePlanForm/utils/coveragePlanApiToOptionMappers";

export const useGetEquipmentOptions = () => {
  const { data, error, isLoading } = useSWR<
    EquipmentModeCategoryDto[],
    AxiosError
  >(
    "/api/options/equipment",
    (url) =>
      axios
        .get<EquipmentModeCategoryDto[]>(url)
        .then((response) => response.data),
    { keepPreviousData: true, revalidateOnFocus: false }
  );

  return {
    data: data ? mapEquipmentToOptions(data) : [],
    error,
    isLoading,
  };
};
