import { useState } from "react";
// components
import {
  Accordion,
  Icon,
  Label,
  Confirm,
  AccordionTitleProps
} from "semantic-ui-react";

interface CarrierServiceAreaProps {
  selectedItems: { id: number; serviceArea: string }[];
  options: { id: number; serviceArea: string }[];
  removeArea: (itemToRemove: { id: number; serviceArea: string }) => void;
  addArea: (x: { serviceArea: string }) => void;
  showConfirm?: boolean;
}

const CarrierServiceArea = ({
  selectedItems,
  options,
  removeArea,
  addArea,
  showConfirm = true,
}: CarrierServiceAreaProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [idToRemove, setIdToRemove] = useState<number | null>(null);

  const handleAccordionClick = (_: React.MouseEvent, titleProps: AccordionTitleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(Number(newIndex));
  };

  const handleConfirm = (id: number) => {
    const itemToRemove = selectedItems.find(
      (x) => x.id === (!showConfirm ? id : idToRemove)
    );
    if (itemToRemove) {
      removeArea(itemToRemove);
      setIdToRemove(null);
    }
  };

  const handleCancel = () => {
    setIdToRemove(null);
  };

  const handleRemoveClick = (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    setIdToRemove(id);
    if (!showConfirm) {
      handleConfirm(id);
    }
  };

  return (
    <>
      <Accordion className="customAccordion">
        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={handleAccordionClick}
        >
          <Icon name="dropdown" />
          {selectedItems?.length > 0
            ? selectedItems?.map((x) => (
                <Label key={`saadd_${x.id}`}>
                  {x.serviceArea}
                  <Icon
                    title="delete"
                    name="delete"
                    onClick={(e: React.MouseEvent) => {
                      handleRemoveClick(e, x.id);
                    }}
                  />
                </Label>
              ))
            : "No service areas"}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          {options?.map((x) => (
            <Label
              key={`sarmv_${x.serviceArea}`}
              className="serviceArea"
              as="a"
              color="grey"
              basic
              onClick={() => addArea(x)}
            >
              <Icon name="add" color="green" link />
              {x.serviceArea}
            </Label>
          ))}
        </Accordion.Content>
      </Accordion>
      <Confirm
        open={!!idToRemove && showConfirm}
        header="Delete service area"
        content="Are you sure you want to delete this service area?"
        onCancel={handleCancel}
        onConfirm={() => handleConfirm(idToRemove as number)}
      />
    </>
  );
};

export default CarrierServiceArea;
