import React, { useCallback, useMemo } from "react";
import momentTz from "moment-timezone";
import { Icon, Label } from "semantic-ui-react";
import cx from "classnames";
import { User } from "oidc-client";

import CarrierTriangleBadge from "../CarrierTriangleBadge";
import { Activity } from "models/Activity";
import TableProfileImage from "components/TableProfileImage";
import { CarrierEntity } from "components/CarrierList/models";
import CarrierStar from "components/CarrierStar";
import { MarketplaceCarrierIcon } from "components/MarketplaceIcon";
import { IS_EU } from "shared/constants/envConstants";
import { formatPhoneNumberWithExtentionAndCountryCode } from "shared/utils/utility";
import * as activityConstants from "shared/constants/activityConstants";

import styles from "../CarrierList.module.scss";

type ListRowProps = {
  item: CarrierEntity;
  onRowClick: (item: CarrierEntity) => void;
  onEditCarrier: (id: number) => void;
  user?: User;
};

const createContentSummary = (act: Activity) => {
  const summaryEntityType =
    activityConstants.entityTypes.find((type) => type.key === act.entityType)
      ?.text ?? "";

  const summaryAction =
    activityConstants.activityTypes.find((type) => type.key === act.actionType)
      ?.text ?? "";

  const user = act.userName ? `${act.userName}: ` : "";

  return `${user} ${summaryAction} ${summaryEntityType}`;
};

const ListRow = (props: ListRowProps) => {
  const phoneValue = useMemo(() => {
    if (!props.item.phone) return "Phone n/a";

    const phone = {
      phoneNumber: props.item.phone?.phoneNumber ?? "",
      countryCode: props.item.phone?.countryCode ?? "",
      extension: props.item.phone?.extension ?? "",
    };

    return formatPhoneNumberWithExtentionAndCountryCode(phone)
  }, [props.item.phone]);

  const lastActivitySummary = useMemo(
    () => (props.item.lastActivity ? createContentSummary(props.item.lastActivity) : "none"),
    [props.item.lastActivity]
  );

  const notesVal = props.item?.lastActivity?.notes ?? "n/a";
  const notesTitle = (props.item?.lastActivity?.notes?.length ?? 0) > 58 ? props.item.lastActivity.notes : null;

  const handleCarrierEdit = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      props.onEditCarrier(props.item.id);
    },
    [props]
  );

  const renderAccountName = () => {
    if (props.item.owner) {
      return (
        <div className={styles.lastActivityData}>
          <p className={styles.lastActivityHeader} title={lastActivitySummary}>
            {props.item.name}
          </p>
          {props.item.owner && (
            <p
              className={cx(
                styles.lastActivityNotes,
                props.item.owner.name ===
                  `${props.user?.profile.firstName} ${props.user?.profile.lastName}`
                  ? styles.blue
                  : null,
              )}
              title={notesTitle ?? ""}
            >
              {props.item.owner.name}
            </p>
          )}
        </div>
      );
    }

    return <p>{props.item.name}</p>;
  };

  return (
    <div
      id={`list_row_${props.item.id}`}
      className={styles.carrierRow}
      onClick={() => props.onRowClick(props.item)}
    >
      <CarrierTriangleBadge
        key={`${props.item.id}_ctb`}
        turvoId={props.item.turvoId}
        statusId={props.item.statusCodeId}
      />

      <div>
        <CarrierStar
          key={`cstar_${props.item.id}`}
          starredByCurrentUser={props.item.isStarredByCurrentUser}
          starredByOthers={props.item.starredByUsers}
        />
        <TableProfileImage item={props.item} />
      </div>

      <div
        className={cx(styles.carrierName, styles.container)}
        title={props.item.name}
      >
        {renderAccountName()}
      </div>

      <div className={styles.numericData} title={props.item.mcNumber}>
        <p>{props.item.mcNumber}</p>
      </div>

      <div className={styles.numericData} title={props.item.dotNumber}>
        <p>{props.item.dotNumber}</p>
      </div>

      <div className={styles.phone} title={phoneValue}>
        <p>{phoneValue}</p>
      </div>

      <div className={styles.additionalData} title={props.item.email}>
        <p style={{ maxWidth: "150px" }}>{props.item.email || "Email n/a"}</p>
      </div>

      <div className={styles.additionalData} title={props.item.address}>
        <p>{props.item.address || "n/a"}</p>
      </div>

      <div className={styles.lastActivityTime}>
        <p>
          {props.item.lastActivity?.createdAt
            ? momentTz
              .utc(props.item.lastActivity.createdAt)
              .tz("America/New_York")
              .format("MM/DD/YY h:mm A")
            : "never"}
        </p>
      </div>

      <div className={styles.lastActivityData}>
        <p className={styles.lastActivityHeader} title={lastActivitySummary}>
          {lastActivitySummary}
        </p>
        <p className={styles.lastActivityNotes} title={notesTitle ?? ""}>
          {notesVal}
        </p>
      </div>

      {!IS_EU && (
        <div className={styles.attributes}>
          <MarketplaceCarrierIcon profile={props.item} />
        </div>
      )}

      <div className={styles.matchesAndBids}>
        <p>
          {props.item.totalActiveMatches ?? 0}/{props.item.totalActiveCapacities ?? 0}
        </p>
      </div>

      <div className={styles.editIconContainer}>
        <Icon name="edit" link onClick={handleCarrierEdit} title="edit-icon" />
      </div>
      {props.item.unseenNotificationsCount > 0 && (
        <Label
          style={{ position: "absolute", right: "3px", top: "30px" }}
          circular
          color="blue"
          size="tiny"
        >
          {props.item.unseenNotificationsCount > 99
            ? "99+"
            : props.item.unseenNotificationsCount}
        </Label>
      )}
    </div>
  );
};

export default ListRow;
