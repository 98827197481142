import axios, { AxiosError } from "axios";
import useSWR, { SWRResponse } from "swr";

import { CoveragePlanDto } from "../../../../../models/dto/CoveragePlanDto";
import { QueryGridResponse } from "../../../../../models/QueryGridResponse";

type UseGetCoveragePlansArgs = {
  page: number;
  pageSize: number;
  search?: string;
  statuses?: CoveragePlanDto["status"][];
  customer?: CoveragePlanDto["customer"];
  createdBy?: CoveragePlanDto["createUser"];
  sortField?: keyof CoveragePlanDto;
  dir?: "asc" | "desc";
  onlyMyPlans?: boolean;
};

export const useGetCoveragePlans = ({
  page,
  pageSize,
  search = "",
  statuses = [],
  customer = null,
  createdBy = null,
  sortField = "createDate",
  dir = "desc",
  onlyMyPlans = false,
}: UseGetCoveragePlansArgs): SWRResponse<
  QueryGridResponse<CoveragePlanDto>,
  AxiosError
> => {
  const params = new URLSearchParams({
    page: page.toString(),
    pageSize: pageSize.toString(),
    sortField,
    dir,
    ...(search && { name: search }),
    ...(customer && { customerId: customer.id.toString() }),
    ...(createdBy && { createTurvoUserId: createdBy.id }),
  });

  for (const status of statuses) {
    params.append("statuses", status);
  }

  const baseUrl = onlyMyPlans
    ? "/api/coverageplan/list/user"
    : "/api/coverageplan/list";

  return useSWR<QueryGridResponse<CoveragePlanDto>, AxiosError>(
    `${baseUrl}?${params.toString()}`,
    (url) =>
      axios
        .get<QueryGridResponse<CoveragePlanDto>>(url)
        .then((response) => response.data),
    { keepPreviousData: true }
  );
};
